<template>
  <form @submit.prevent="SaveProfile">
    <b-modal v-model="$store.state.user.editingProfile" has-modal-card full-screen :can-cancel="false" class="profile-editor">
      <div class="modal-card">
        <section class="roomHeader" :style="{ borderColor: 'var(--userColor)' }">
          <div class="exit-button mr-2" @click="$store.commit('user/EditProfile', false)">
            <d-icon icon="FaArrowLeft" class="has-text-black-bis" />
          </div>
          <h5 class="title pb-2 is-size-5 has-text-black-bis">{{ $t('profile.editProfile') }}</h5>

        </section>

        <section class="modal-card-body">
          <!-- avatar  -->
          <b-field :label="$t('profile.avatar')" class="avatarSelector mb-5">
            <b-radio v-model="player.avatar" name="avatar" v-for="(av, i) in $store.state.avatars" :key="i"
              :native-value="i">
              <div class="playerAvatar" :class="{ selected: i == player.avatar }"
                :style="{ backgroundColor: `#${player.color}` }">
                <img :src="av.image.sizes.thumbnail.url" />
              </div>
            </b-radio>
          </b-field>
          <!-- color -->
          <b-field :label="$t('profile.color')">
            <input type="range" min="0" max="359" step="1" value="50" class="slider" id="hueSlider" v-model="hueVal">
          </b-field>
          <!-- username -->
          <b-field :label="$t('profile.username')" class="mb-0">
            <b-input v-model="player.username" ref="usernameField" expanded required maxlength="25" pattern="(?!.*\s$)(?=.*[a-zA-Z].*[a-zA-Z])^[a-zA-Z0-9\s\u00C0-\u024FñÑáéíóúÁÉÍÓÚ]+$" @blur="FixUsername()" ></b-input>
            <!-- El regex valida: que el texto no comience ni termine con espacios, que no contenga caracteres especiales y que contenga al menos 2 letras -->
          </b-field>

          <user-extra-fields ref="extraFields" />
        </section>

        <footer class="modal-card-foot is-justify-content-space-between">
          <b-button @click="showDialogLogOut = true" type="is-primary" outlined expanded
            icon-left="right-from-bracket">
            <d-icon icon="FaRightFromBracket" size="is-small" />&nbsp; {{ $t('system.logout') }}
          </b-button>
          <button type="submit" class="button is-primary is-fullwidth" :disabled="saving">{{ $t('system.save')
          }}</button>
        </footer>

        <!-- Logout Dialog (Pop up) -->
        <b-modal v-model="showDialogLogOut" scroll="keep" :can-cancel="false">
          <div class="modal-card">
            <header class="modal-card-head has-background-white">
              <p class="modal-card-title is-size-5">{{ $t('system.areYouSure') }}</p>
            </header>
            <section v-if="player.email == ''" class="modal-card-body has-background-white-ter">
              {{ $t('system.loseYourAccount') }}</section>
            <footer class="modal-card-foot">
              <b-button :label="$t('system.cancel')" type="is-primary" size="is-small" outlined expanded
                @click="showDialogLogOut = false" />
              <b-button @click="$store.dispatch('user/logout')" :label="$t('system.logout')" type="is-primary"
                size="is-small" expanded />
            </footer>
          </div>
        </b-modal>

      </div>
    </b-modal>
  </form>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { hsv2hex, hex2hsl } from "color-functions";
import UserExtraFields from "./UserExtraFields.vue";
// import whitelabelConfig from "@/whitelabel.config";

export default {
  components: { UserExtraFields },
  props: {},
  data() {
    return {
      hueVal: 100,
      tacDisabled: false,
      saving: false,
      player: {
        username: this.$store.state.user.profile.username,
        email: this.$store.state.user.profile.confirmed
          ? this.$store.state.user.profile.email
          : "",
        color: this.$store.state.user.profile.color,
        avatar: this.$store.state.user.profile.avatar,
        acceptTerms: this.$store.state.user.profile.acceptTerms
      },
      showDialogLogOut: false,
    };
  },
  watch: {
    hueVal(newVal) {
      newVal = parseInt(newVal)
      this.player.color = hsv2hex(
        newVal,
        this.$store.getters["user/ColorSaturation"],
        this.$store.getters["user/ColorBrightness"]
      ).substring(1);
    },
    opened(opened) {
      if (opened) {
        Vue.nextTick(() => {
          this.Reset()
        })
      }
    },
  },
  computed: {
    opened() {
      return this.$store.state.user.editingProfile
    },
    ShowExperienceInviteModal() {
      if (!this.$store.state.user) return false
      if (this.$store.state.user.experienceInviteData == null) return false
      return this.$store.state.user.experienceInviteShow || false
    },
  },
  methods: {
    FixUsername(){
      Vue.set(this.player, 'username', this.player.username.trim())
      Vue.nextTick(() => {
        this.$refs.usernameField.checkHtml5Validity();
      })
    },
    Reset() {
      this.player.username = this.$store.state.user.profile.username;
      this.player.email = this.$store.state.user.profile.confirmed
        ? this.$store.state.user.profile.email
        : "";
      this.player.color = this.$store.state.user.profile.color;
      this.hueVal = hex2hsl(this.player.color).h;
      this.player.avatar = this.$store.state.user.profile.avatar;
      this.acceptTerms = this.$store.state.user.profile.acceptTerms;
      this.tacDisabled = this.$store.state.user.profile.acceptTerms

      this.$refs.extraFields.LoadFieldsFromStore()
    },
    async SaveProfile() {
      if (!this.$refs.extraFields.validate()) {
        return
      }
      this.saving = true
      axios
        .put(process.env.VUE_APP_API_BASE + "/api/users/" + this.$store.state.user.profile.id,
          { ...this.player, ...this.$refs.extraFields.RetrieveFields() },
          {
            headers: {
              Authorization: "JWT " + this.$store.state.user.token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (this.player.email != "") {
              this.$store.commit("user/SetEmail", this.player.email);
            }
            this.$store.commit("user/SetUser", { profile: res.data.doc });
            this.$store.commit("user/EditProfile", false);

            this.$buefy.notification.open({
              type: "is-primary",
              message: this.$t('notifications.profileUpdated')
            })

            this.$sfxPlay('positivenotification')
          }
        })
        .catch(() => {

          this.$buefy.notification.open({
            type: "is-danger",
            message: this.$t('notifications.errorUpdatingProfile')
          })

          this.$sfxPlay('negatovenotification')

        })
        .finally(() => {
          this.saving = false
        });
    },
  },
  mounted() {
    this.hueVal = hex2hsl(this.player.color).h //this.hex2hue(this.player.color)
    this.tacDisabled = this.$store.state.user.profile.acceptTerms
  }
};
</script>